import React from 'react';
import getStyles from './styles';
import { useStyles } from "react-styles-hook";
import RoutineCard from './RoutineCard';
// import Routinecover from '../../../static/routinecover.png'
import Image1 from "../../../static/hairwash1.svg";
import Image2 from "../../../static/hairwash2_demeler-min.png";
import Image3 from "../../../static/hairwash3_soigner-min.png";
import Image4 from "../../../static/hairwash4_coiffer-min.png";

const RoutineCategories = ({ lang }) => {
  const classes = useStyles(getStyles());
  return (
    <div>
      <div style={classes.parent} className="w-full">
        {/* <img
          alt="Image de fond"
          src={Routinecover}
          style={classes.absolute}
          className="w-3/5 xl:w-3/4 z-0 opacity-50"
        /> */}
        <div
          style={classes.abosluteImages}
          className="w-full grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-12 lg:gap-16 md:flex z-20 md:pt-20 lg:pt-40 px-10"
        >
          <div className="w-full">
            <RoutineCard
              image={Image1}
              step="Etape 1"
              stepDescription="Laver"
              link={`${lang}/produits/femme/01-soins-des-cheveux-femme/01-laver-cheveux-femme`}
            />
          </div>
          <div className="w-full md:pt-28">
            <RoutineCard
              image={Image2}
              step="Etape 2"
              stepDescription="Déméler"
              link={`${lang}/produits/femme/01-soins-des-cheveux-femme/02-demeler-femmes-soins-des-cheveux`}
            />
          </div>
          <div className="w-full">
            <RoutineCard
              image={Image3}
              step="Etape 3"
              stepDescription="Soigner"
              link={`${lang}/produits/femme/01-soins-des-cheveux-femme/03-soigner`}
            />
          </div>
          <div className="w-full md:pt-28">
            <RoutineCard
              image={Image4}
              step="Etape 4"
              stepDescription="Coiffer"
              link={`${lang}/produits/femme/01-soins-des-cheveux-femme/04-coiffer-femme-soins`}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default RoutineCategories
// Prévoir proptyes
