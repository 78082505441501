import React from 'react';
import CustomButton from '../CustomButton';


const AboutCard = ({ lang }) => {

  return (
    <div className='w-11/12 mx-auto flex flex-wrap md:flex-row-reverse items-center mb-4 md:mb-6'>
      <div className='w-full md:w-1/2 flex justify-center items-center'>
        <div className='w-full md:w-2/3 flex flex-col items-center'>
          <h2 className='text-xs md:text-xl font-bold pb-4 md:pb-6'>Notre <span className="text-orange-dark">mission</span></h2>
          <p className='text-center text-xxm md:text-sm pb-4 md:pb-6'>Zawema est la première marketplace dédiée aux cheveux bouclés, frisés et crépus. Cette plateforme digitale met en relation des vendeurs et des acheteurs de produits et de services adaptés aux cheveux  texturés, c'est à dire les cheveux non lisses.</p>
          <p className='text-center text-xxm md:text-sm pb-4 md:pb-6'>Le site Zawema.com est un concept store numérique qui réunit toute l'offre dédiée à cette texture de cheveux. Tous les cheveux méritent ce qu'il y a de mieux. Zawema libère la beauté des cheveux !</p>
          <CustomButton link={`/${lang}/advice/la-mission-de-zawema`} text={`Découvrir`} type={`button`} />
        </div>
      </div>
      <div className='w-full md:w-1/2 md:mt-0 py-6 md:py-20'>
        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div className="relative mx-auto w-full rounded-lg shadow-lg">
            <button
              type="button"
              className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <iframe
                srcdoc="
              <style>
                body, .full {
                  width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
                    }
              </style>
              <a
                href='https://www.youtube.com/embed/RcaRT2-9dXY'
                class='full'
              >
                <img
                  src='https://vumbnail.com/RcaRT2-9dXY.jpg'
                  class='full'
                />
                <svg
                  version='1.1'
                  viewBox='0 0 68 48'
                  width='68px'
                  style='position: relative;'
                >
                  <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                  <path d='M 45,24 27,14 27,34' fill='#fff'></path>
                </svg>
              </a>
              "
                width="464"
                height="383"
                className="w-full"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              ></iframe>
            </button>
          </div>
        </div>
      </div>
    </div >
  )
};

export default AboutCard
