import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { withTranslation } from "react-i18next";
import CustomButton from '../CustomButton';
import { capitalizeFirst } from '../../utils/format';

const ContentBox = ({ title, text, textButton, linkButton, lang, t }) => {
  i18n(lang);
 
  return (
    <div className="w-full flex justify-center mb-6 md:mb-10">
      <div className="w-full md:w-11/12 py-6 md:py-10 px-4 md:px-16 bg-orange flex flex-col items-center">
        <h2 className='text-xs md:text-lg font-bold pb-4 md:pb-6'>{capitalizeFirst(t(title))}</h2>
        <p className='text-center text-xxm md:text-sm pb-4 md:pb-6'>{capitalizeFirst(t(text))}</p>
        <CustomButton
          buttonStyle="bg-black text-white"
          link={linkButton}
          text={textButton}
        />
      </div>
    </div>
  )
};

export default withTranslation()(ContentBox)

ContentBox.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textButton: PropTypes.string,
  linkButton: PropTypes.string,
  lang: PropTypes.string
};
