import React from 'react';
import PropTypes from "prop-types";
import getStyles from './styles';
import { useStyles } from "react-styles-hook";
import { Link } from 'gatsby';

const RoutineCard = ({ image, step, stepDescription, link }) => {
  const classes = useStyles(getStyles());
  return (
    <Link to={`/${link}`} >
      <div
        className="w-full flex justify-center flex-wrap"
      >
        <img
          className="w-full opacity-60"
          src={image}
          alt="Femme qui se lave les cheveux avec le bon produit hydratant"
        />
        <div
          className="bg-gradient-to-b from-orange-dark via-orange-dark to-orange-fade w-2/3 -top-6 lg:-top-10 relative h-12 lg:h-20"
        >
          <p className="text-center text-xs text-white" style={classes.step}>
            {step}
          </p>
          <p className="text-center text-xs md:text-s lg:text-base xl:text-lg font-bold text-white">
            {stepDescription}
          </p>
        </div>
      </div>
    </Link>
  )
};

export default RoutineCard;

RoutineCard.propTypes = {
  image: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
  stepDescription: PropTypes.string.isRequired,
};