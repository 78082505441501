import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../CustomButton';
import ProductListCard from '../ProductListCard';

const SelectionZawema = ({ products, lang }) => {
  const productFilter = products.sort((a, b) => 0.5 - Math.random()).splice(0, 6)

  return (
    <div className="flex flex-col items-center w-full px-4 md:px-8 mb-4 md:mt-6">
      <h2 className='text-sm md:text-xl font-bold pb-4 md:pb-6'>La sélection <span className="text-orange-dark">Zawema</span></h2>
      <div className={`w-full grid grid-cols-2 gap-4 md:gap-8 mb-6 pb-4 ${(products.length === 4) ? "md:grid-cols-4" : "md:grid-cols-3"}`}>
        {
          productFilter.map((product, key) => {
            return (
              <ProductListCard key={key} product={product} />
            )
          })
        }
      </div>
      <CustomButton link={`/${lang}/selection`} text={`Voir plus`} />
    </div>
  )
};

export default SelectionZawema

SelectionZawema.propTypes = {
  products: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired
};
