import React from "react";
import i18n from "../../../i18n";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import NavBarMenu from "../../components/NavBarMenu";
import AboutCard from "../../components/AboutCard";
import RoutineCategories from "../../components/RoutineCategories";
import SelectionZawema from "../../components/SelectionZawema";
import CarouselInfo from "../../components/CarouselInfo";
import ContentBox from '../../components/ContentBox';
import generateMeta from '../../utils/meta';

function generateTotalCategoryHierarchy(node) {
  if (!node) return [];
  let prefix = [];
  if (typeof node.parentCategory !== undefined) {
    prefix = generateTotalCategoryHierarchy(node.parentCategory);
  }
  prefix.push(node);
  return prefix;
}

function generateTotalSlug(node) {
  if (!node) return "";
  let prefix = "";
  if (typeof node.parentCategory !== undefined) {
    prefix = generateTotalSlug(node.parentCategory);
  }
  return prefix + "/" + node.slug;
}

const HomePage = ({ data, pageContext, t }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  // i18n used for translation
  i18n(pageContext.locale);

  // used for navBarMenu
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];


  // data for banner cover
  let ArrayImageDesktop = [];
  let ArrayImageMobile = [];

  const today = new Date();

  data?.bannerHomePage?.edges?.map(({ node: bannerContent }) => {
    // Finally we remove the product from the banner. If the client we it back, we have to add, in the GraphQL request :
    // product {
    //   seller {
    //     corporateName
    //   }
    //   title
    //   slug
    //   cataloger
    //   flapSelectionZawema
    //   principalSveCategory {
    //     categoryName
    //     slug
    //   }
    //   secondarySveCategories {
    //     categoryName
    //     slug
    //   }
    // }
    // }
    // let product;
    // const defaultVariant = bannerContent.product?.childContentfulFicheProduitProductsFieldJsonNode.products.filter(variant => variant.offers.some(offer => JSON.parse(offer.isAvailable)))[0] || null;
    // const defaultImageId = defaultVariant?.images[0];
    // const defaultImage = ((data.categoryImages || {}).edges || [1]).filter(({ node: image }) => image.contentful_id === defaultImageId)[0]?.node || null;
    // const defaultOffer = defaultVariant?.offers[0];

    // if (bannerContent.product !== null && defaultVariant) {
    //   const startDate = defaultOffer.crossedPriceStartDate !== null && defaultOffer.crossedPriceStartDate !== "null" ? new Date(defaultOffer.crossedPriceStartDate) : null;
    //   const endDate = defaultOffer.crossedPriceEndDate !== null && defaultOffer.crossedPriceEndDate !== "null" ? new Date(defaultOffer.crossedPriceEndDate) : null;
    //   let price = Number(defaultOffer.priceWithVat);
    //   let crossedPrice = defaultOffer.crossedPriceWithVat;
    //   if (crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
    //     price = Number(defaultOffer.crossedPriceWithVat);
    //     crossedPrice = null;
    //   }
    //   product = {
    //     title: bannerContent.product.title,
    //     id: defaultVariant.id,
    //     image: defaultImage || null,
    //     price: price,
    //     crossedPrice: crossedPrice,
    //     corporateName: bannerContent.product.seller.corporateName,
    //     sellerId: bannerContent.product.cataloger || {},
    //     urlProductPage: `/${pageContext.locale}/produits/${bannerContent.product.principalSveCategory.slug}/${bannerContent.product.slug}`,
    //     flapSelectionZawema: JSON.parse(bannerContent.product.flapSelectionZawema),
    //     offerId: defaultOffer.id,
    //   }
    // };
    const desktopBanner = {
      urlImage: bannerContent.imageDesktop,
      alt: bannerContent.title1,
      textButton: bannerContent.linkText,
      urlButton: bannerContent.link,
      title1: bannerContent.title1,
      title2: bannerContent.title2,
      // productInfo: product
    };
    ArrayImageDesktop.push(desktopBanner);
    const mobileBanner = {
      urlImage: bannerContent.imageMobile === null ? bannerContent.imageDesktop : bannerContent.imageMobile,
      alt: bannerContent.title1 === null ? bannerContent.imageDesktop.title1 : bannerContent.title1,
      textButton: bannerContent.linkText,
      urlButton: bannerContent.link,
      title1: bannerContent.title1,
      title2: bannerContent.title2,
      // productInfo: product
    };
    ArrayImageMobile.push(mobileBanner);
  });

  // for product in flapSelection
  let productCoverImage = data.productCoverImage.edges.filter(({ node: productContent }) => {
    if (productContent.childContentfulFicheProduitProductsFieldJsonNode == null) return false;
    const variants = productContent.childContentfulFicheProduitProductsFieldJsonNode.products || [];
    let productContentHasAtLeastOneAvailableOffer = false;
    variants.map((variant) => {
      (variant.offers || []).forEach((offer) => {
        if (JSON.parse(offer.isAvailable)) {
          productContentHasAtLeastOneAvailableOffer = true;
        }
      });
    });

    return productContentHasAtLeastOneAvailableOffer;
  }).map(({ node: productContent }) => {
    const defaultVariant = productContent.childContentfulFicheProduitProductsFieldJsonNode.products.filter(variant => variant.offers.some(offer => JSON.parse(offer.isAvailable)))[0];
    const defaultImageId = defaultVariant.images[0];
    const defaultImage = ((data.categoryImages || {}).edges || [1]).filter(({ node: image }) => image.contentful_id === defaultImageId)[0]?.node || null;
    const defaultOffer = defaultVariant.offers[0];
    const defaultSeller = productContent.seller.corporateName || {};
    const defaultSellerId = productContent.cataloger || {};
    const urlProductPage = `/${pageContext.locale}/produits/${productContent.principalSveCategory.slug}/${productContent.slug}`
    const startDate = defaultOffer.crossedPriceStartDate !== null && defaultOffer.crossedPriceStartDate !== "null" ? new Date(defaultOffer.crossedPriceStartDate) : null;
    const endDate = defaultOffer.crossedPriceEndDate !== null && defaultOffer.crossedPriceEndDate !== "null" ? new Date(defaultOffer.crossedPriceEndDate) : null;
    let price = Number(defaultOffer.priceWithVat);
    let crossedPrice = defaultOffer.crossedPriceWithVat;
    if ((startDate !== null && endDate !== null) && crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
      price = Number(defaultOffer.crossedPriceWithVat);
      crossedPrice = null;
    }
    return {
      title: productContent.title,
      id: defaultVariant.id,
      image: defaultImage || null,
      price: price,
      crossedPrice: crossedPrice,
      corporateName: defaultSeller,
      sellerId: defaultSellerId,
      urlProductPage: urlProductPage,
      flapSelectionZawema: JSON.parse(productContent.flapSelectionZawema),
      offerId: defaultOffer.id,
    }
  });

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang={pageContext.locale} />
        <title>Accueil - Zawema</title>
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      {(data?.bannerHomePage?.edges) && (
        <CarouselInfo imageDesktop={ArrayImageDesktop} imageMobile={ArrayImageMobile} lang={pageContext.locale} isHomePage={true} />
      )}
      <SelectionZawema products={productCoverImage} lang={pageContext.locale} />
      <RoutineCategories lang={pageContext.locale} />
      <ContentBox
        title="homePage.needHelpTitle"
        text="homePage.coachingTeaser"
        textButton="Coaching"
        linkButton={`/${pageContext.locale}/produits/coaching-capillaire`}
        lang={pageContext.locale}
      />
      <AboutCard lang={pageContext.locale} />
    </Layout>
  );
};

export default withTranslation()(HomePage);

export const query = graphql`
  query( $locale: String! ) {
    menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
      edges {
        node {
          slug
          categoryName
        }
      }
    }
    menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
            }
          } 
        }
      }
    }
    allContentfulCategorySve(filter: { node_locale: { eq: $locale } }) { 
      edges {
        node {
          categoryName
          categoryEncartTitle
          slug
          parentCategory {
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    bannerHomePage: allContentfulBannerHomePage(filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          link
          linkText
          title1
          title2
          imageDesktop {
            gatsbyImageData(width:1920)
          }
          imageMobile {
            gatsbyImageData(width:1920)
          }
        }
      }
    }
    productCoverImage: allContentfulFicheProduit(filter: {flapSelectionZawema: {eq: true}, node_locale: {eq: $locale}}) {
      edges {
        node {
          seller {
            corporateName
          }
          title
          slug
          cataloger
          childContentfulFicheProduitProductsFieldJsonNode {
              products {
                gtin
                id
                images
                offers {
                  crossedPriceEndDate
                  crossedPriceStartDate
                  crossedPriceWithVat
                  id
                  isAvailable
                  leadtimeToShip
                  priceWithVat
                  sellerId
                  sellerSku
                }
              }
          }
          cataloger
          flapSelectionZawema
          principalSveCategory {
            categoryName
            slug
          }
          secondarySveCategories {
            categoryName
            slug
          }
        }
        previous {
          slug
        }
      }
    }
    categoryImages: allContentfulAsset(filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          title
          file {
            url
          }
          description
          contentful_id
        }
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/homePage.md/"}) {
      html
      frontmatter {
          title
          description
          ogtitle
          ogdescription
      }
    }
  }
`;
