const getStyles = (theme) =>({
    parent:{
        position: "relative",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    absolute:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    absoluteImages:{
        position: "absolute",
        top:"-30px",
        left:"0px"
    }
});

export default getStyles;