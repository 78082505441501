import React from "react";
import PropTypes from "prop-types";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'gatsby';
// import ProductListCard from '../ProductListCard';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import getStyles from "./styles";
import { useStyles } from "react-styles-hook";

const CarouselInfo = ({
  imageDesktop,
  imageMobile,
  lang,
  isHomePage
}) => {

  let isBanner = false;
  if (isHomePage) {
    isBanner = true;
  }

  const classes = useStyles(getStyles());

  return (
    <>
      {/* CoverImage in desktop */}
      <div className="w-full h-full hidden md:block">
        {{ imageDesktop } && (
          <Carousel
            autoPlay={true}
            interval={5000}
            infiniteLoop
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            showIndicators={true}
            renderItem={(item) => (
              <div className="w-full flex justify-center items-center">
                {item}
              </div>
            )}
          >
            {
              imageDesktop.map((image, key) => {
                const imageGen = getImage(image.urlImage);
                return (
                  <div key={key} className="relative w-full flex justify-center items-center">
                    <GatsbyImage image={imageGen} alt={image.alt ? image.alt : ""} layout="fullWidth" placeholder="blurred" style={classes.filter} />
                    <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center">
                      {/* {
                        (image.productInfo !== null && image.productInfo !== undefined) && (
                          <>
                            <div className={`w-2/3 h-full flex flex-col justify-center items-center ${(image.title1 && image.title2) ? "space-y-24" : "transform translate-y-1/4"}`}>
                              {(image.title1 && image.title2) && (
                                <div>
                                  <h2 className="flex flex-col justify-center items-center">
                                    <span className="text-xl text-white">{image.title1}</span>
                                    <span className={image.title2.length < 15 ? "font-titleFont text-center md:text-xl lg:text-xxxl text-white" : "font-titleFont text-center text-lg lg:text-xxl text-white"}>{image.title2}</span>
                                  </h2>
                                </div>
                              )}
                              {(image.urlButton && image.textButton) && (
                                <Link
                                  className="bg-orange-dark py-2 px-10 text-sm text-black"
                                  to={`/${lang}/${image.urlButton}`}
                                >
                                  {image.textButton}
                                </Link>
                              )}
                            </div>
                            {(image.productInfo !== null && image.productInfo !== undefined) ? (
                              <>
                                <div className={`lg:hidden w-full h-full flex flex-col justify-center items-center ${isBanner ? "px-10 md:px-4" : ""} `}>
                                  <div className="bg-white py-2 sm:py-12 px-4 mb-2 flex justify-center items-center">
                                    <div className="w-full flex flex-col items-center">
                                      <img src={image.productInfo.image?.file?.url} alt={image.productInfo.image?.file ? image.productInfo.image.title : ""} className="h-16 object-contain" />
                                      <p className="text-xxm line-clamp-2">{image.productInfo.title}</p>
                                      <p className="text-xxs font-bold">{image.productInfo.corporateName}</p>
                                      <div className="flex">
                                        <h4 className={"text-center text-xs font-semibold text-orange-darkest"}>
                                          {((image.productInfo.price * 100) / 100).toFixed(2)} €
                                        </h4>
                                        {(image.productInfo.crossedPrice !== 'null' && image.productInfo.crossedPrice !== null && image.productInfo.crossedPrice !== '0' && image.productInfo.crossedPrice !== 0) && (
                                          <h4 className={"text-center text-xs line-through text-orange-crossed"}>
                                            {image.productInfo.crossedPrice} €
                                          </h4>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {(image.urlButton && image.textButton) && (
                                    <Link
                                      className="bg-orange-dark py-2 px-6 text-xxm text-black"
                                      to={`/${lang}/${image.urlButton}`}
                                    >
                                      {image.textButton}
                                    </Link>
                                  )}
                                </div>
                                <div className="w-1/3 h-full hidden lg:flex justify-center items-center">
                                  <div className="bg-white p-4 w-4/5 h-4/5 flex justify-center items-center">
                                    <ProductListCard product={image.productInfo} productBanner={true} />
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </>
                        )
                      } */}
                      {(!image.productInfo && image.urlButton && image.textButton && !image.title1 && !image.title2) && (
                        <div className="w-full h-full transform translate-y-1/4 flex justify-center items-center">
                          <Link
                            className="bg-black py-2 px-10 text-sm text-white"
                            to={`/${lang}/${image.urlButton}`}
                          >
                            {image.textButton}
                          </Link>
                        </div>
                      )}
                      {(!image.productInfo && image.title1 && image.title2) && (
                        <div className="w-full h-full flex flex-col justify-center items-center space-y-24">
                          <div>
                            <h2 className="flex flex-col justify-center items-center">
                              <span className="text-xl text-white">{image.title1}</span>
                              <span className={image.title2.length < 15 ? "font-titleFont text-center text-xxxl text-white" : "font-titleFont text-center text-lg lg:text-xxl text-white"}>{image.title2}</span>
                            </h2>
                          </div>
                          {(image.urlButton && image.textButton) && (
                            <Link
                              className="bg-black py-2 px-10 text-sm text-white"
                              to={`/${lang}/${image.urlButton}`}
                            >
                              {image.textButton}
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })
            }
          </Carousel>
        )}
      </div>

      {/* CoverImage in mobile */}
      <div className="w-full h-full md:hidden mb-4">
        {{ imageMobile } && (
          <Carousel
            autoPlay={true}
            interval={5000}
            infiniteLoop
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            renderItem={(item) => (
              <div className="w-full flex justify-center items-center">
                {item}
              </div>
            )}
          >
            {
              imageMobile.map((image, key) => {
                const imageGen = getImage(image.urlImage);
                return (
                  <div key={key} className="relative w-full flex justify-center items-center">
                    <GatsbyImage image={imageGen} alt={image.alt ? image.alt : ""} layout="fullWidth" placeholder="blurred" style={classes.filter} />
                    <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center">
                      {/* {
                        (image.productInfo) && (
                          <div className={`w-full h-full flex flex-col justify-center items-center ${isBanner ? "px-10 md:px-4" : ""} `}>
                            <div className="bg-white py-2 sm:py-12 px-4 mb-2 flex justify-center items-center">
                              <div className="w-full flex flex-col items-center">
                                <img src={image.productInfo.image?.file?.url} alt={image.productInfo.image?.file ? image.productInfo.image.title : ""} className="h-16 object-contain" />
                                <p className="text-xxm line-clamp-2">{image.productInfo.title}</p>
                                <p className="text-xxs font-bold">{image.productInfo.corporateName}</p>
                                <div className="flex">
                                  <h4 className={"text-center text-xs font-semibold text-orange-darkest"}>
                                    {((image.productInfo.price * 100) / 100).toFixed(2)} €
                                  </h4>
                                  {(image.productInfo.crossedPrice !== 'null' && image.productInfo.crossedPrice !== null && image.productInfo.crossedPrice !== '0' && image.productInfo.crossedPrice !== 0) && (
                                    <h4 className={"text-center text-xs line-through text-orange-crossed"}>
                                      {image.productInfo.crossedPrice} €
                                    </h4>
                                  )}
                                </div>
                              </div>
                            </div>
                            {(image.urlButton && image.textButton) && (
                              <Link
                                className="bg-orange-dark py-2 px-6 text-xxm text-black"
                                to={`/${lang}/${image.urlButton}`}
                              >
                                {image.textButton}
                              </Link>
                            )}
                          </div>
                        )} */}
                      {(!image.productInfo && image.urlButton && image.textButton) && (
                        <div className={`w-full h-full flex flex-col justify-center items-center ${(image.title1 && image.title2) ? "space-y-10" : "transform translate-y-16"}`}>
                          {(image.title1 && image.title2) && (
                            <div>
                              <h2 className="flex flex-col justify-center items-center">
                                <span className="text-base text-white leading-3">{image.title1}</span>
                                <span className={image.title2.length < 15 ? "font-titleFont text-center text-xxl text-white" : "font-titleFont text-center text-lg lg:text-xxl text-white"}>{image.title2}</span>
                              </h2>
                            </div>
                          )}
                          <Link
                            className="bg-black py-2 px-6 text-xs text-white"
                            to={`/${lang}/${image.urlButton}`}
                          >
                            {image.textButton}
                          </Link>
                        </div>
                      )}
                      {(!image.productInfo && !image.urlButton && !image.textButton && image.title1 && image.title2) && (
                        <div className={`w-full h-full flex flex-col justify-center items-center ${(image.title1 && image.title2) ? "space-y-10" : "transform translate-y-16"}`}>
                          <div>
                            <h2 className="flex flex-col justify-center items-center px-10">
                              <span className="text-base text-white leading-3">{image.title1}</span>
                              <span className={image.title2.length < 15 ? "font-titleFont text-center text-xxl text-white" : "font-titleFont text-center text-lg lg:text-xxl text-white"}>{image.title2}</span>
                            </h2>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })
            }
          </Carousel>
        )}
      </div>
    </>
  );
};

export default CarouselInfo;

CarouselInfo.propTypes = {
  imageMobile: PropTypes.oneOfType([PropTypes.array]),
  imageDesktop: PropTypes.oneOfType([PropTypes.array]),
};

